<template>
    <div>
        <Modal>
            <form @submit.prevent="onSubmit">
                <div class="title"> {{ !obj_queja.numero_folio ? 'Crear nueva queja' : 'Datos de la queja' }}</div>

                <div v-if="!obj_queja.numero_folio">
                    <div class="alert alert-primary mx-3 my-3" role="alert">
                        <i class="fa-solid fa-circle-info"></i>
                        Para garantizar que tu queja se procese adecuadamente, por favor, asegúrate de proporcionar
                        información completa y precisa en todos los campos del formulario.
                    </div>
                </div>
                <div v-else>
                    <div class="alert alert-warning mx-3 my-3" role="alert">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                        Para garantizar una actualización precisa de tu queja, por favor, asegúrate de proporcionar
                        información actualizada y detallada en los campos que deseas actualizar.
                    </div>
                </div>

                <div class="body">

                  <div  v-if="pagina === 1">
                    <h5>
                      Información de la queja
                      <br>
                      <small class="text-secondary">(Paso 1/3)</small>
                    </h5>

                    <div class="row justify-center">
                      <div class="col-md-6 my-3">
                        <label for="tipo_persona">Tipo persona</label>
                        <select v-model="obj_queja.tipo_persona" name="tipo_persona" id="tipo_persona"
                                class="form-control" :disabled="!editable">
                          <option value="1">Persona Física</option>
                          <option value="2">Persona Moral</option>
                        </select>
                      </div>


                      <div class="col-md-6 my-3">
                        <label for="genero">Género</label>
                        <select v-model="obj_queja.sexo" name="genero" id="genero" class="form-control"
                                :disabled="!editable">
                          <option value="H">Hombre</option>
                          <option value="M">Mujer</option>
                        </select>
                      </div>


                      <div class="col-md-6 my-3">
                        <label for="edad">Edad</label>
                        <input v-model="obj_queja.edad" type="number" name="edad" id="edad" class="form-control"
                               :disabled="!editable">
                      </div>


                      <div class="col-md-6 my-3">
                        <label for="numero_queja">Número de queja</label>
                        <input v-model="obj_queja.numero_queja" type="text" name="numero_queja" id="numero_queja"
                               class="form-control" disabled>
                      </div>


                      <div class="col-md-6 my-3">
                        <label for="mes_a_informar">Mes a informar de la queja</label>
                        <select v-model="obj_queja.mes_a_informar" name="mes_a_informar" id="mes_a_informar"
                                class="form-control" :disabled="!editable">
                          <option v-for="mes in meses" :value="mes.value" :key="mes.value">{{ mes.label }}
                          </option>
                        </select>

                      </div>

                      <div class="col-md-6 my-3">
                        <label for="fecha_queja">Fecha de la queja</label>
                        <input v-model="obj_queja.fecha_queja" type="date" name="fecha_queja"
                               id="fecha_queja" class="form-control" :disabled="!editable">
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="prioridad">Prioridad de la queja</label>
                        <select v-model="obj_queja.prioridad" name="prioridad" id="prioridad" class="form-control"
                                :disabled="!editable">
                          <option value="SI">Si</option>
                          <option value="NO">No</option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="estado_queja">Estado de la queja</label>
                        <select v-model="obj_queja.estado_queja" name="estado_queja" id="estado_queja"
                                class="form-control" :disabled="!editable" @change="cambio_estatus_queja()">
                          <option value="1">Pendiente</option>
                          <option value="2">Concluido</option>
                        </select>
                      </div>
                    </div>
                  </div>


                  <div  v-else-if="pagina === 2">
                    <h5>
                      Información de la queja
                      <br>
                      <small class="text-secondary">(Paso 2/3)</small>
                    </h5>

                    <div class="row justify-center">
                      <div class="col-md-6 my-3">
                        <label for="medio_de_recepcion">Medio de recepción</label>
                        <select v-model="obj_queja.medio_de_recepcion" name="medio_de_recepcion"
                                id="medio_de_recepcion" class="form-control" :disabled="!editable">
                          <option v-for="medio in medios_recepciones" :value="medio.valor" :key="medio.valor">
                            {{ medio.nombre }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="nivel_de_atencion">Nivel de atención</label>
                        <select v-model="obj_queja.nivel_de_atencion" name="nivel_de_atencion"
                                id="nivel_de_atencion" class="form-control" :disabled="!editable">
                          <option v-for="nivel in niveles_atenciones" :value="nivel.valor" :key="nivel.valor">
                            {{ nivel.nombre }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="producto_servicio">Producto servicio</label>
                        <select v-model="obj_queja.producto_servicio" name="producto_servicio"
                                id="producto_servicio" class="form-control" :disabled="!editable" @change="obtener_casuas">
                          <option v-for="producto in productos_servicios" :value="producto.valor"
                                  :key="producto.valor">
                            {{ producto.nombre }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="causa_queja">Causa de la queja</label>
                        <select v-model="obj_queja.causa_queja" name="causa_queja" id="causa_queja"
                                class="form-control" :disabled="!editable">
                          <option v-for="causa in causas_financieras" :value="causa.valor">
                            {{ causa.nombre }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="codigo_postal">Codigo postal</label>
                        <input v-model="obj_queja.codigo_postal" @blur="buscar_codigo_postal" type="text"
                               maxlength="5" name="codigo_postal" id="codigo_postal" class="form-control"
                               :disabled="!editable">
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="colonia">Colonia</label>
                        <select v-model="obj_queja.colonia" name="colonia" id="colonia" class="form-control"
                                :disabled="!editable">
                          <option v-for="colonia in colonias" :value="colonia.valor" :key="colonia.valor">
                            {{ colonia.nombre }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="estado">Estado</label>
                        <select v-model="obj_queja.estado" name="estado" id="estado" class="form-control"
                                :disabled="!editable">
                          <option v-for="estado in estados" :value="estado.valor" :key="estado.valor">
                            {{ estado.nombre }}</option>
                        </select>
                      </div>

                      <div class="col-md-6 my-3">
                        <label for="entidad">Delegación / Municipio</label>
                        <select v-model="obj_queja.entidad" name="entidad" id="entidad" class="form-control"
                                :disabled="!editable">
                          <option v-for="municipio in municipios" :value="municipio.valor" :key="municipio.valor">
                            {{ municipio.nombre }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>


                  <div  v-else>
                    <h5>
                      Información de la queja
                      <br>
                      <small class="text-secondary">(Paso 3/3)</small>

                      <div class="row justify-center">

                        <div class="col-md-6 my-3">
                          <label for="localidad">Localidad</label>
                          <select v-model="obj_queja.localidad" name="localidad" id="localidad" class="form-control"
                                  :disabled="!editable">
                            <option v-for="localidad in localidades" :value="localidad.valor"
                                    :key="localidad.valor">{{ localidad.nombre }}
                            </option>
                          </select>
                        </div>

                        <div class="col-md-6 my-3" v-if="obj_queja.estado_queja == '2'">
                          <label for="sentido_de_resolucion">Sentido de resolución</label>
                          <select v-model="obj_queja.sentido_de_resolucion" name="sentido_de_resolucion"
                                  id="sentido_de_resolucion" class="form-control" :disabled="!editable ">
                            <option value="1">Totalmente favorable al usuario</option>
                            <option value="2">Desfavorable al Usuario</option>
                            <option value="3">Parcialmente favorable al usuario</option>
                          </select>
                        </div>


                        <div class="col-md-6 my-3">
                          <button v-if="estatus_pendiente" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo es debe estar vacio si el estado de la queja es Pendiente "><i class="fa fa-info-circle"></i></button>
                          <label for="fecha_resolucion">Fecha de resolución</label>
                          <input v-model="obj_queja.fecha_resolucion"
                                 type="date" name="fecha_resolucion"
                                 id="fecha_resolucion" class="form-control" :disabled="!editable || estatus_pendiente">
                        </div>

                        <div class="col-md-6 my-3">
                          <button v-if="estatus_pendiente" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El campo es debe estar vacio si el estado de la queja es Pendiente "><i class="fa fa-info-circle"></i></button>
                          <label for="fecha_notificacion_usuario">Fecha de notificación del usuario</label>
                          <input v-model="obj_queja.fecha_notificacion_usuario"
                                 type="date" name="fecha_notificacion_usuario" id="fecha_notificacion_usuario" class="form-control"
                                 :disabled="!editable || estatus_pendiente">
                        </div>

                        <div class="col-md-6 my-3">
                          <label for="tipo_penalizacion">Tipo penalización</label>
                          <select v-model="obj_queja.tipo_penalizacion" name="tipo_penalizacion"
                                  id="tipo_penalizacion" class="form-control" :disabled="!editable">
                            <option value="1">Contractuales - Cancelación del contrato</option>
                            <option value="2">Contractuales - Reasignación de cartera</option>
                            <option value="3">Económicas - Multa</option>
                          </select>
                        </div>
                        
                        <div class="col-md-6 my-3">
                          <label for="numero_penalizacion">Número de penalizaciones impuestas al despacho de cobranza</label>
                          <input v-model="obj_queja.numero_penalizacion" type="number" name="numero_penalizacion"
                                 id="numero_penalizacion" class="form-control" :disabled="!editable">
                        </div>


                      </div>
                    </h5>



                </div>
                </div>

                <div class="footer">
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button class="btn btn-secondary mr-2" type="button" @click="anterior"
                                :disabled="pagina === 1">
                                <i class="fa-solid fa-arrow-left"></i>
                                Anterior
                            </button>
                            <button class="btn btn-primary mr-2" type="button" @click.prevent="show_modal_queja = true"
                                v-if="(pagina === 3 && !obj_queja.id) || (obj_queja.id && evento_id)">
                              <i class="fa-solid fa-floppy-disk"></i>
                                {{ evento_id ? 'Actualizar' : 'Guardar' }}
                            </button>

                            <button class="btn btn-primary mr-2" type="button"
                                v-if="pagina === 3 && obj_queja.id && !evento_id"
                                @click.prevent="show_modal_editar_queja_evento = true">
                              <i class="fa-solid fa-floppy-disk"></i>
                                Editar
                            </button>

                            <button class="btn btn-secondary mr-2" type="button" @click="siguiente"
                                :disabled="pagina === 3" v-if="pagina !== 3">
                                Siguiente
                                <i class="fa-solid fa-arrow-right"></i>
                            </button>

                          <br>
                          <button class="btn btn-danger mt-3 mr-2" type="button" @click.prevent="$emit('close')">
                            <i class="fa-solid fa-xmark"></i>
                            Cancelar
                          </button>
                        </div>
                    </div>
                </div>

            </form>

            <!--Inicio Confirmar datos queja -->
            <Modal v-if="show_modal_queja" :options="{ width: '30vw', type: 'danger' }">
                <div class="title"><i class="fa fa-warning mr-2"></i> Mensaje importante</div>
                <div class="body">
                    <h5 class="font-weight-bold">
                        ¿Estás seguro de que todos los datos han sido cargados correctamente en el formulario?
                    </h5>
                </div>
                <div class="footer">
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button class="btn btn-primary mr-2" type="button" @click.prevent="onSubmit">
                              <i class="fa-solid fa-check"></i>
                              Confirmar
                            </button>
                            <button class="btn btn-danger" type="button" @click.prevent="show_modal_queja = false">
                              <i class="fa-solid fa-xmark"></i>
                              Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <!--Inicio Confirmar datos queja -->

            <!--Inicio Creacion de Evento Queja Editar -->
            <Modal v-if="show_modal_editar_queja_evento" :options="{ width: '40vw', type: 'warning' }">
                <div class="title text-white">Mensaje importante</div>
                <div class="h6 p-3">
                    ¿Por qué motivo deseas actualizar los datos de la queja? Por favor, explícalo brevemente en el campo
                    correspondiente.
                </div>
                <form @submit.prevent="eventoSutmit">
                    <div class="body">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <label for="motivo">Descripción</label>
                                <textarea v-model="evento_queja.motivo" name="motivo" id="motivo" cols="30" rows="5"
                                    class="form-control" />
                            </div>

                        </div>
                    </div>
                    <div class="footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <button class="btn btn-primary mr-2" type="button" @click.prevent="eventoSutmit">
                                  <i class="fa-solid fa-check"></i>
                                  Confirmar
                                </button>
                                <button class="btn btn-danger" type="button" @click.prevent="cerrar_modal_evento()">
                                  <i class="fa-solid fa-xmark"></i>
                                  Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>

            <!--Final Creacion de Evento Queja Editar -->
        </Modal>
    </div>
</template>

<script>
import apiCatalogos from '@/apps/condusef/api/catalogos';
import apiSepomex from '@/apps/condusef/api/sepomex';
import apiCondusef from "@/apps/condusef/api/quejas";
import Modal from '@/components/Modal';
import Evento from "./Evento.vue";

const meses = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
];

export default {
    components: { Modal, Evento },
    props: {
        queja: {
            type: Object,
        },
    },
    data() {
        return {
            obj_queja: {
                mes_a_informar: 1,
                numero_queja: 1,
                fecha_queja: null,
                medio_de_recepcion: null,
                nivel_de_atencion: null,
                producto_servicio: null,
                causa_queja: null,
                prioridad: null,
                estado_queja: null,
                codigo_postal: null,
                colonia: null,
                estado: null,
                entidad: null,
                localidad: null,
                tipo_persona: 1,
                sexo: null,
                edad: null,
                fecha_resolucion: null,
                fecha_notificacion_usuario: null,
                sentido_de_resolucion: null,
                numero_penalizacion: null,
                tipo_penalizacion: null,
            },
            editable: false,
            show_editar: null,
            evento_id: null,
            pagina: 1,
            meses,
            medios_recepciones: [],
            niveles_atenciones: [],
            productos_servicios: [],
            causas_financieras: [],
            colonias: [],
            estados: [],
            municipios: [],
            localidades: [],
            show_modal_queja: false,
            show_modal_editar_queja_evento: false,
            evento_queja: {
                nombre: 'actualizacion_de_queja',
                motivo: null
            }
        }
    },
    mounted() {
        if (this.queja) {
            this.obj_queja = this.$helper.clone(this.queja);
            this.editable = false;
            this.buscar_codigo_postal()
        } else {
            this.editable = true
        }

        this.obtener_catalogos();
    },
  computed:{

      estatus_pendiente(){
        return this.obj_queja.estado_queja && this.obj_queja.estado_queja == 1;
      }
  },


  methods: {

        cerrar_modal_evento(){
          this.evento_queja.motivo = null
          this.show_modal_editar_queja_evento = false
        },
        cambio_estatus_queja(){
          // si el esaatus es pendiente los siguientes campos no son requeridos (se pasán a nulo)
          if (this.estatus_pendiente) {
            this.obj_queja.fecha_resolucion = null
            this.obj_queja.fecha_notificacion_usuario = null
          }
        },
        anterior() {
            if (this.pagina > 1) {
                this.pagina--;
            }
        },
        siguiente() {
            if (this.pagina < 3) {
                this.pagina++;
            }
        },
        async obtener_catalogos() {
            try {
                const catalogos = (await apiCatalogos.listar_catalogos_reune()).data;
                for (const catalogo of catalogos) {
                    if (catalogo.codigo == 'medios_de_recepcion')
                        this.medios_recepciones = catalogo.opciones;
                    else if (catalogo.codigo == 'niveles_de_atencion')
                        this.niveles_atenciones = catalogo.opciones;
                    else if (catalogo.codigo == 'lista_de_productos')
                        this.productos_servicios = catalogo.opciones;
                }
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        },

        async buscar_codigo_postal() {
            try {
                const response = (await apiSepomex.codigos_postales(this.obj_queja.codigo_postal)).data;
                this.colonias = response.colonias
                this.estados = response.estados
                this.municipios = response.municipios
                this.localidades = response.localidades
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        },
      async obtener_casuas(){

        this.obj_queja.causa_queja = null
        this.causas_financieras = []

        const url_options = {
          filters: [{
            relacion: "and",
            columna: "codigo",
            operador: "equal_to",
            valor1: 'causas_producto_'+this.obj_queja.producto_servicio,
            valor2: null
          }]
        }
        const response = (await apiCatalogos.listar_catalogos(url_options)).data[0]
        this.causas_financieras = response.opciones

      },
        async onSubmit() {

            this.show_modal_queja = false;
            try {
                let response = null;
                if (!this.obj_queja.numero_folio) {
                    response = (await apiCondusef.crear_queja(this.obj_queja)).data
                    this.$helper.showMessage('Crear Queja', 'Queja guardada exitosamente.', 'success', 'alert')
                } else {
                    response = (await apiCondusef.actualizar_queja(this.obj_queja.numero_folio, this.obj_queja)).data
                    this.$helper.showMessage('Editar Queja', 'Queja actualizada exitosamente.', 'info', 'alert')
                }
                this.$log.info('response', response);
                this.$emit('update');
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        },
        async eventoSutmit() {
            try {
                let response = (await apiCondusef.actualizar_queja_evento(this.obj_queja.id, this.evento_queja)).data
                this.$helper.showMessage('Motivo actualización de queja', 'Creaste un motivo para la actualización de la queja', 'success', 'alert');
                this.evento_id = response.id
                this.editable = true
                this.show_modal_editar_queja_evento = false
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        }
    },

};

</script>